// This is a partial.
// It lies in <source>/_sass, just waiting to be imported.
// It does not contain the YAML front matter and has no corresponding output file in the built site.

// Navigation Variables
$content-width: 1000px;
$nav-breakpoint: 1024px;
$nav-height: 70px;

// Outer navigation wrapper
.navigation {
  height: $nav-height;
}

// Container with no padding for navbar
.nav-container {
  max-width: $content-width;
  margin: 0 auto;
  display: inline;
}

// Navigation 
nav {
  float: right;
  padding-top: 1rem;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float: left;
      position: relative;
      a,
      a:visited {
        display: block;
        padding: 0 20px;
        line-height: $nav-height;
        font-size: 1.5rem;
        text-decoration: none;
        &.active {
          color: $yellow;
          font-weight: 700;
        }
      }
    }
  }
}

nav ul.nav-list {
  .button-cta.secondary {
    line-height: 1.3;
    padding: .4rem 1rem;
    margin-top: 1rem;
    text-transform: none;
  }
  li:hover .nav-submenu{
    display: block;
  }
  li a {
    font-size: 1.1rem;
    padding: 0 10px;
  }
}

.nav-submenu{
  background: $light-gray;
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 0.5em 0;
  transform: translateX(-50%);
  &:before{
    content: '';
    position: absolute;
    border-bottom: 6px solid $light-gray;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.nav-submenu-item{
  min-width: 12rem;
  text-align: left;
  a{
    line-height: 2rem;
  }
  a:hover{
    background: $blue;
    color: $white;
  }
}

/* Mobile navigation */

// Binds to JS Toggle
.nav-mobile {
  display: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: $nav-height;
  width: $nav-height;
}
@media only screen and (max-width: $nav-breakpoint) {
  .navigation {
    padding-top: 2rem;
    padding-right: 2rem;
  }
  .nav-mobile {
    display: block;
    z-index: 1;
  }
  .logo-wrapper {
    display: block;
    margin: 0 auto;
    padding-top: 0;
  }
  nav {
    width: 16rem;
    padding-top: 0;
    .nav-list {
      background-color: $yellow;
      position: relative;
      left: 100px;
      top: -80px;
      padding: 3rem 1rem 1rem 1rem;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 30px;
      @media screen and (max-width: 468px) {
        left: 50px;
      }
      .button-cta.secondary {
        display: none;
      }
    }
    ul {
      display: none;
      li {
        float: none;
        a,
        a:visited {
          padding: 15px;
          line-height: 20px;
          color: $dark-blue;
          &.active {
            color: $dark-blue;
            font-weight: 700;
          }
        }
        ul li a { padding-left: 30px; }
      }
    }
  }
}

@media screen and (min-width: $nav-breakpoint) {
  .nav-list { display: block !important; }
}

#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: $white;
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
  }
  span:before { top: -10px; }
  span:after { bottom: -10px; }
  &.active span {
    background-color: transparent;
    &:before,
    &:after { top: 0; }
    &:before { transform: rotate(45deg); }
    &:after { transform: rotate(-45deg); }
  }
}
@media only screen and (max-width: 480px) {
  nav { width: 18rem; }
}
