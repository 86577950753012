// PROJECT color palette

$cyan : #00FFFF;
$dark-blue : #09131E;


// Overwrites link colors in /core/colors.scss
$link : #00FFFF;
$link-hover : #008080;
$link-visited : #666666;
