/** 

This is a partial.
It lies in <source>/_sass, just waiting to be imported.
It does not contain the YAML front matter and has no corresponding output file in the built site. 

*/

// RED HAT CORPORATE COLORS

$red: #CC0000;
$dark-red: #A30000;
$darker-red: #820000;
$black : #000000;
$white : #FFFFFF;

// RED HAT SECONDARY COLORS
$blue: #004153;
$light-blue: #A3DBE8;
$dark-gray: #4C4C4C;
$light-gray: #DCDCDC;

// RED HAT ACCENT COLORS
$accent-blue: #00b9E4;
$accent-purple: #3B0083;
$accent-yellow: #F0AB00;
$accent-green: #92D400;
$accent-teal: #007A87;


// RED HAT PRODUCT COLORS

$storage-yellow: #f0ab00;
$storage-orange: #ec7a08;
$infrastructure-blue: #00b9e4;
$infrastructure-dark-blue: #0088ce;
$applicationdev-green: #92d400;
$applicationdev-dark-green: #3f9c35;

// Other colors 
$link : #0000EE;
$link-visited : #551A8B;

$orange: #E37B40;
$dark-orange: #85372C;
$yellow: #F0CA4D;
$dark-yellow: #90792E;
$teal: #46B29D;
$dark-blue: #1E2E37;
$dark-teal: #2A6B5E;

//Grayscale
$gray-0 : #EFEFEF;
$gray-1 : #AAAAAA;
$gray-2 : #555555;
$gray-3 : #333333;
$gray-4 : #222222;


// Alternative spelling
$grey-0 : #EFEFEF;
$grey-1 : #AAAAAA;
$grey-2 : #555555;
$grey-3 : #333333;
$grey-4 : #222222;
$dark-grey: #4C4C4C;
$light-grey: #DCDCDC;
