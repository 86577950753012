.libraries-standards-band {
  //background-color: $black;
  h2 {
    color: $cyan;
  }
  .block {
    text-align: center;
    a {
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
