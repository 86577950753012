.docs-archive-subnav {
  .img-caption {
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    font-weight: 200;
    padding: 1rem;
    background-color: $blue;
    color: $white;
    margin-top: -7px;
  }
}
