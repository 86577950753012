$baseurl: "";

@import "core/global";
@import "core/grid";
@import "core/colors";
@import "core/asciidoc";
@import "core/includes/header-navigation";
@import "core/includes/product-download-band";
@import "core/includes/quick-pitch-band";
@import "core/includes/secondary-page-title-band";
@import "core/includes/download-announcement-band";
@import "core/includes/two-column-content-band";
@import "core/includes/mid-page-cta-band";
@import "core/includes/docs-archive-subnav";
@import "core/includes/contributions-band";
@import "core/includes/big-three-subnav-band";
@import "core/includes/video-content-band";
@import "core/includes/highlights-inline-images-band";
@import "core/includes/highlights-alternating-images-band";
@import "core/includes/highlights-three-columns-band";
@import "core/includes/download-release-band";
@import "core/includes/contact-form-band";

@import "project-colors";
@import "devworx";
@import "asciidoc";
@import "components";
@import "layouts/home";
@import "layouts/contact";
@import "layouts/blog";
@import "layouts/about";
@import "includes/header-navigation";
@import "includes/libraries-standards-band";
@import "includes/homepage-highlights-band";
@import "includes/homepage-hero-band";
@import "includes/feedback-community-band";
@import "includes/project-footer";
@import "includes/devworx-footer";
@import "includes/tabs";
@import "includes/share-page";
