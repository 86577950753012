.homepage-highlights-band {
  background-color: rgba(0, 0, 0, 0.80);

  h2 {
    color: $cyan;
  }
  .highlights-alternating-inline-block {
    padding: 2rem 0 4rem 0;
  }
  .block-image,
  .block-content{
    align-self: center;
  }

  .block-content pre {
    max-width: 350px;
  }
}
