// This is a partial.
// It lies in <source>/_sass, just waiting to be imported.
// It does not contain the YAML front matter and has no corresponding output file in the built site.

.homepage {
  background: url($baseurl + '/assets/images/bg-home.jpg') no-repeat center center fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  background-size: cover;
  height: 100%;
  width: 100%;

  p {
    margin: 2rem 0;
  }
}
