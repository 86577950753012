$breakpoint-m: 48em;

.grid-wrapper,
.component-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1em;
  clear: both;
}

.width-1-12 {
  grid-column: span 1;
}

.width-2-12 {
  grid-column: span 2;
}

.width-3-12 {
  grid-column: span 3;
}

.width-4-12 {
  grid-column: span 4;
}

.width-5-12 {
  grid-column: span 5;
}

.width-6-12 {
  grid-column: span 6;
}

.width-7-12 {
  grid-column: span 7;
}

.width-8-12 {
  grid-column: span 8;
}

.width-9-12 {
  grid-column: span 9;
}

.width-10-12 {
  grid-column: span 10;
}

.width-11-12 {
  grid-column: span 11;
}

.width-12-12 {
  grid-column: span 12;
}

.width-1-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 1;
  }
}

.width-2-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 2;
  }
}

.width-3-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 3;
  }
}

.width-4-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 4;
  }
}

.width-5-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 5;
  }
}

.width-6-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 6;
  }
}

.width-7-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 7;
  }
}

.width-8-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 8;
  }
}

.width-9-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 9;
  }
}

.width-10-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 10;
  }
}

.width-11-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 11;
  }
}

.width-12-12-m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 12;
  }
}

