.big-three-subnav-band {
  .big-three-block {
    text-align: center;
    img {
      max-width: 14rem;
    } 
    h3 {
      margin: 1rem 0;
    }
  }
}
