.project-footer,
.content.project-footer {
  background-color: #0D0D12;
  padding-top: 4rem;
  padding-bottom: 6rem;

  .logo-wrapper {
    text-align: center;
    display: block;
    .project-logo {
      max-width: 18rem;
      @media screen and (max-width: 768px) {
        max-width: 10rem;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: .875rem;
    @media screen and (max-width: 1024px) {
      grid-column: 1/6;
    }
    @media screen and (max-width: 480px) {
      order: 2;
      grid-column: span 12;
    }
  }

  .project-links {
    font-size: .875rem;
    @media screen and (max-width: 1024px) {
      grid-column: span 2;
    }
    @media screen and (max-width: 480px) {
      order: 1;
      grid-column: span 6;
    }
  }

  ul.footer-links {
    list-style: none;
    padding-left: 0;
    a { font-size: .875rem; }
  }
  .more-links {
    font-size: .875rem;
    padding-left: 2rem;
    margin-left: 2rem;
    border-left: 2px solid white;
    ul {
      a { font-size: .875rem; }
    }
    @media screen and (max-width: 1024px) {
      grid-column: span 12;
      border-top: 4px solid white;
      border-left: none;
      padding: 3rem 0 2rem 0;
      margin-bottom: 1rem;
      margin-left: 0;
    }
    @media screen and (max-width: 480px) {
      order: 3;
    }
  }
}

.footer-section {
  margin-bottom: 3rem;
  padding: 1rem 0;
  @media screen and (max-width: 480px) {
    margin-bottom: 1rem;
  }
}
